<template>
  <b-form-group
    label="İşlenen Malzeme Cinsi"
    label-for="id_material_types"
  >
    <v-select
      id="id_material_types"
      v-model="formData.id_material_types"
      placeholder="Seçiniz"
      label="title"
      :reduce="item => item.id"
      :options="materialTypes"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MaterialTypes',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    materialTypes() {
      return this.$store.getters['materialTypes/getMaterial_types']
    },
  },
  created() {
    this.getMaterialTypes()
  },
  methods: {
    getMaterialTypes() {
      this.$store.dispatch('materialTypes/material_typesList', {
        select: [
          'material_types.id AS id',
          'material_types.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
