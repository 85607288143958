<template>
  <b-form-group
    label="Kullanılan Proses"
    label-for="id_used_processes"
  >
    <v-select
      id="id_used_processes"
      v-model="formData.id_used_processes"
      placeholder="Seçiniz"
      label="title"
      :reduce="item => item.id"
      :options="usedProcesses"
      @input="changeProcess"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MaterialTypes',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    usedProcesses() {
      return this.$store.getters['usedProcesses/dataList']
    },
  },
  created() {
    this.getUsedProcesses()
  },
  methods: {
    getUsedProcesses() {
      this.$store.dispatch('usedProcesses/getDataList', {
        select: [
          'used_processes.id AS id',
          'used_processes.title AS title',
        ],
      })
    },
    changeProcess() {
      if (this.formData.id_used_processes) {
        this.formData.id_used_products = null
        this.getUsedProducts(this.formData.id_used_processes)
      }
    },
    getUsedProducts(id) {
      this.$store.dispatch('usedProducts/getDataList', {
        select: [
          'used_products.id AS id',
          'used_products.title AS title',
        ],
        where: {
          'used_products.id_used_processes': id,
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
