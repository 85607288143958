<template>
  <b-form-group
    label="Görünüm"
    label-for="id_appearances"
  >
    <v-select
      id="id_appearances"
      v-model="formData.id_appearances"
      placeholder="Seçiniz"
      label="title"
      :reduce="item => item.id"
      :options="appearances"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MaterialTypes',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    appearances() {
      return this.$store.getters['appearances/getAppearances']
    },
  },
  created() {
    this.getAppearances()
  },
  methods: {
    getAppearances() {
      this.$store.dispatch('appearances/appearancesList', {
        select: [
          'appearances.id AS id',
          'appearances.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
