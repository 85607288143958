<template>
  <b-form-group
    label="Genel Durum"
    label-for="general_situation"
  >
    <v-select
      id="job"
      v-model="formData.general_situation"
      placeholder="Seçiniz"
      label="title"
      :reduce="item => item.id"
      :options="generalSituations"
    >
      <template v-slot:option="option">
        <span
          :style="'color:' + option.color"
        >
          <FeatherIcon icon="CheckCircleIcon" />
        </span>
        {{ option.title }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'GeneralSituation',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    generalSituations() {
      return [
        {
          id: 1,
          title: 'Yeşil',
          color: '#447c00',
        },
        {
          id: 2,
          title: 'Sarı',
          color: '#b7af00',
        },
        {
          id: 3,
          title: 'Kırmızı',
          color: '#c90000',
        },
      ]
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
