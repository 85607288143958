<template>
  <div>
    <div
      v-if="id === 1"
    >
      <b-button
        variant="gradient-success"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="CheckCircleIcon" />
      </b-button>
    </div>
    <div
      v-else-if="id === 2"
    >
      <b-button
        variant="gradient-warning"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="CheckCircleIcon" />
      </b-button>
    </div>
    <div
      v-else-if="id === 3"
    >
      <b-button
        variant="gradient-danger"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="CheckCircleIcon" />
      </b-button>
    </div>
    <div v-else>
      -
    </div>
  </div>
</template>
<script>
import { BBadge, BButton } from 'bootstrap-vue'

export default {
  name: 'GeneralSituation',
  components: {
    BBadge,
    BButton,
  },
  props: {
    id: Number,
  },
}
</script>

<style scoped>

</style>
