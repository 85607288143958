<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col>
            <b-form-group label="Makine Kartı Bul">
              <b-input-group>
                <b-form-input
                  v-model="machineKeyword"
                  placeholder="Makine Kodu, Proje Kodu, Müşteri Adı..."
                  @keydown.enter="searchMachine"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="searchMachine"
                  >
                    <FeatherIcon icon="SearchIcon" />
                    Bul
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card v-if="machineSelected">
      <b-card-title>
        {{ machine.company }}
        <small>{{ machine.customer_address }}</small>
      </b-card-title>
      <b-card-text>
        <b-list-group>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Kodu
            </div>
            {{ machine.mcode }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Proje Numarası
            </div>
            {{ machine.project }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Fabrika / Seri No
            </div>
            {{ machine.serial }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Kurulum Yılı
            </div>
            {{ machine.syear }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Tipi
            </div>
            {{ machine.machine_type }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Modeli
            </div>
            {{ machine.machine_model }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Temizleme Teknolojisi
            </div>
            {{ machine.machine_cleaning }}
          </b-list-group-item>
          <b-list-group-item>
            <div class="font-weight-bold text-primary">
              Makine Durumu
            </div>
            {{ machine.machine_status }}
          </b-list-group-item>
        </b-list-group>
        <div class="text-center mt-2">
          <b-button
            variant="primary"
            @click="changeMachine"
          >
            <FeatherIcon icon="CheckCircleIcon" />
            Makine Değiştir
          </b-button>
        </div>
      </b-card-text>
    </b-card>
    <b-row v-if="!machineSelected">
      <b-col
        v-for="(machine,mkey) in machines"
        :key="mkey"
        cols="12"
        md="6"
        lg="4"
      >
        <b-card>
          <b-card-title>
            {{ machine.company }}
            <small>{{ machine.customer_address }}</small>
          </b-card-title>
          <b-card-text>
            <b-list-group>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Kodu
                </div>
                {{ machine.mcode }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Proje Numarası
                </div>
                {{ machine.project }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Fabrika / Seri No
                </div>
                {{ machine.serial }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Kurulum Yılı
                </div>
                {{ machine.syear }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Tipi
                </div>
                {{ machine.machine_type }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Modeli
                </div>
                {{ machine.machine_model }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Temizleme Teknolojisi
                </div>
                {{ machine.machine_cleaning }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Durumu
                </div>
                {{ machine.machine_status }}
              </b-list-group-item>
            </b-list-group>
            <div class="text-center mt-2">
              <b-button
                variant="primary"
                @click="getMachine(machine.id)"
              >
                <FeatherIcon icon="CheckCircleIcon" />
                Makine Seç
              </b-button>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'MachineSearchAddCopy',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      machineSelected: true,
      machineKeyword: null,
    }
  },
  computed: {
    machines() {
      return this.$store.getters['machines/getMachines']
    },
    machine() {
      return this.$store.getters['machines/getMachine']
    },
    formData() {
      return this.$store.getters['services/getService']
    },
  },
  methods: {
    searchMachine() {
      this.machineSelected = false
      const params = {
        or_like: {
          'machines.mcode': this.machineKeyword,
          'machines.project': this.machineKeyword,
          'machines.serial': this.machineKeyword,
          'customers.company': this.machineKeyword,
        },
      }
      this.$store.dispatch('machines/machinesList', params)
    },
    changeMachine() {
      this.machineSelected = false
      this.machineKeyword = null
      this.formData.id_machines = null
      this.formData.id_customers = null
      this.formData.id_customer_address = null
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
        .then(res => {
          this.machineSelected = true
          this.formData.id_machines = res.id
          this.formData.id_customers = res.id_customers
          this.formData.id_customer_address = res.id_customer_address
          this.getCustomer(res.id_customers)
          this.getCustomerAddress(res.id_customer_address)
        })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
  },
}
</script>
