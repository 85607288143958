<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <bench-name />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <related-person />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <related-phone />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <analysed />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <material-types />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <used-oils />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <ref-val />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <general-situation />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <appearances />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <foreign-oil-ratios />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <refractometer />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <concentration />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <ph />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <conductivity />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <bacteria />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <mildew />
      </b-col>
      <b-col cols="12">
        <notes />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Analysed from '@/views/Services/elements/Analysed.vue'
import Appearances from '@/views/Services/elements/Appearances.vue'
import Bacteria from '@/views/Services/elements/Bacteria.vue'
import Concentration from '@/views/Services/elements/Concentration.vue'
import Conductivity from '@/views/Services/elements/Conductivity.vue'
import ForeignOilRatios from '@/views/Services/elements/ForeignOilRatios.vue'
import GeneralSituation from '@/views/Services/elements/GeneralSituation.vue'
import MaterialTypes from '@/views/Services/elements/MaterialTypes.vue'
import Mildew from '@/views/Services/elements/Mildew.vue'
import Notes from '@/views/Services/elements/Notes.vue'
import Ph from '@/views/Services/elements/Ph.vue'
import Refractometer from '@/views/Services/elements/Refractometer.vue'
import RelatedPerson from '@/views/Services/elements/RelatedPerson.vue'
import RelatedPhone from '@/views/Services/elements/RelatedPhone.vue'
import UsedOils from '@/views/Services/elements/UsedOils.vue'
import RefVal from '@/views/Services/elements/RefVal.vue'
import BenchName from '@/views/Services/elements/BenchName.vue'

export default {
  name: 'ServiceForm1',
  components: {
    BRow,
    BCol,
    BCard,
    Analysed,
    Appearances,
    Bacteria,
    Concentration,
    Conductivity,
    ForeignOilRatios,
    GeneralSituation,
    MaterialTypes,
    Mildew,
    Notes,
    Ph,
    Refractometer,
    RelatedPerson,
    RelatedPhone,
    UsedOils,
    RefVal,
    BenchName,
  },
}
</script>
