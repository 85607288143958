<template>
  <b-form-group
    label="Analiz Tarihi"
    label-for="analysed"
  >
    <b-form-datepicker
      id="analysed"
      v-model="formData.analysed"
      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'Analysed',
  components: {
    BFormGroup, BFormDatepicker,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
