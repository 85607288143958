<template>
  <b-row>
    <b-col>
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col>
              <div class="font-weight-bold text-primary">
                İlgili Kişi
              </div>
              {{ serviceData.related_person }}
            </b-col>
            <b-col>
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              {{ serviceData.related_phone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="serviceData.notes">
          <div class="font-weight-bold text-primary">
            Yorum / Notlar
          </div>
          {{ serviceData.notes }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th class="align-middle">
              NUMUNE BİLGİLERİ
            </b-th>
            <b-th
              v-if="formType === '1'"
              class="align-middle"
            >
              TEST METODU
            </b-th>
            <b-th
              v-for="(history,hkey) in serviceHistory"
              :key="hkey"
              class="text-center align-middle"
            >
              {{ hkey + 1 }}
            </b-th>
            <b-th class="text-center align-middle">
              SON
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Analiz Tarihi</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,akey) in serviceHistory"
              :key="akey"
              class="text-center"
            >
              {{ (history.analysed)? moment(history.analysed).format('DD.MM.yyyy') : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.analysed)? moment(serviceData.analysed).format('DD.MM.yyyy') : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>İşlenen Malzeme Cinsi</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,mkey) in serviceHistory"
              :key="mkey"
              class="text-center"
            >
              {{ (history.material_type)? history.material_type : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_material_types)? selectedData(materialTypes,serviceData.id_material_types) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Kullanılan Yağ</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,ukey) in serviceHistory"
              :key="ukey"
              class="text-center"
            >
              {{ (history.used_oil)? history.used_oil : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_used_oils)? selectedData(usedOils,serviceData.id_used_oils) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Kullanılan Proses</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,ukey) in serviceHistory"
              :key="ukey"
              class="text-center"
            >
              {{ (history.used_process)? history.used_process : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_used_processes)? selectedData(usedProcesses,serviceData.id_used_processes) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Kullanılan Ürün</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,ukey) in serviceHistory"
              :key="ukey"
              class="text-center"
            >
              {{ (history.used_product)? history.used_product : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_used_products)? selectedData(usedProducts,serviceData.id_used_products) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Genel Durum</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,okey) in serviceHistory"
              :key="okey"
              class="text-center"
            >
              <general-situation :id="Number(history.general_situation)" />
            </b-td>
            <b-td class="text-center">
              <general-situation :id="Number(serviceData.general_situation)" />
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Görünüm</b-td>
            <b-td v-if="formType === '1'">
              TM07-1
            </b-td>
            <b-td
              v-for="(history,akey) in serviceHistory"
              :key="akey"
              class="text-center"
            >
              {{ (history.appearance)? history.appearance : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_appearances)? selectedData(appearances,serviceData.id_appearances) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Yabancı Yağ Oranı</b-td>
            <b-td v-if="formType === '1'">
              TM07-20
            </b-td>
            <b-td
              v-for="(history,fkey) in serviceHistory"
              :key="fkey"
              class="text-center"
            >
              {{ (history.foreign_oil_ratio)? history.foreign_oil_ratio : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.id_foreign_oil_ratios)? selectedData(foreignOilRatios,serviceData.id_foreign_oil_ratios) : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Refraktometre Değeri</b-td>
            <b-td v-if="formType === '1'">
              TM07-27
            </b-td>
            <b-td
              v-for="(history,rkey) in serviceHistory"
              :key="rkey"
              class="text-center"
            >
              <span v-if="history.refractometer">
                %{{ history.refractometer | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
            <b-td class="text-center">
              <span v-if="serviceData.refractometer">
                %{{ serviceData.refractometer | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Konsantrasyon</b-td>
            <b-td v-if="formType === '1'">
              TM07-28
            </b-td>
            <b-td
              v-for="(history,ckey) in serviceHistory"
              :key="ckey"
              class="text-center"
            >
              <span v-if="history.concentration">
                {{ history.concentration | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
            <b-td class="text-center">
              <span v-if="serviceData.concentration">
                {{ serviceData.concentration | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>pH</b-td>
            <b-td v-if="formType === '1'">
              ASTM D 1287
            </b-td>
            <b-td
              v-for="(history,phkey) in serviceHistory"
              :key="phkey"
              class="text-center"
            >
              {{ (history.ph)? history.ph : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.ph)? serviceData.ph : '-' }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>İletkenlik</b-td>
            <b-td v-if="formType === '1'">
              ASTM D 1125
            </b-td>
            <b-td
              v-for="(history,cokey) in serviceHistory"
              :key="cokey"
              class="text-center"
            >
              {{ (history.conductivity)? history.conductivity + 'µs/cm' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.conductivity)? serviceData.conductivity + 'µs/cm' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Bakteri Testi</b-td>
            <b-td v-if="formType === '1'">
              TM07-15
            </b-td>
            <b-td
              v-for="(history,bakey) in serviceHistory"
              :key="bakey"
              class="text-center"
            >
              {{ (history.bacteria)? history.bacteria : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.bacteria)? serviceData.bacteria : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '1'">
            <b-td>Mantar Testi</b-td>
            <b-td v-if="formType === '1'">
              TM07-15
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.mildew)? history.mildew : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.mildew)? serviceData.mildew : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Toplam Alkalite</b-td>
            <b-td v-if="formType === '1'">
              ml/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.total_alkalinity)? history.total_alkalinity + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.total_alkalinity)? serviceData.total_alkalinity + 'ml/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Toplam Asit</b-td>
            <b-td v-if="formType === '1'">
              ml/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.total_acid)? history.total_acid + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.total_acid)? serviceData.total_acid + 'ml/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Serbest Asit</b-td>
            <b-td v-if="formType === '1'">
              ml/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.free_acid)? history.free_acid + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.free_acid)? serviceData.free_acid + 'ml/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Hızlandırıcı</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.accelerator)? history.accelerator : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.accelerator)? serviceData.accelerator : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Fosfat Noktası</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.phosphate_point)? history.phosphate_point : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.phosphate_point)? serviceData.phosphate_point : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Demir Noktası</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.iron_point)? history.iron_point : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.iron_point)? serviceData.iron_point : '-' }}
            </b-td>
          </b-tr>
          <!-- new lines -->
          <b-tr v-if="formType === '2'">
            <b-td>Zn (Çinko)</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.zn)? history.zn + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.zn)? serviceData.zn + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Mn(Mangan)</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.mn)? history.mn + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.mn)? serviceData.mn + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Ni(Nikel)</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.ni)? history.ni + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.ni)? serviceData.ni + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Serbest Alkalite</b-td>
            <b-td v-if="formType === '1'">
              ml/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.free_alkalinity)? history.free_alkalinity + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.free_alkalinity)? serviceData.free_alkalinity + 'ml/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Toplam Yağ Ekstre</b-td>
            <b-td v-if="formType === '1'">
              ml/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.total_oil_extract)? history.total_oil_extract + 'ml/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.total_oil_extract)? serviceData.total_oil_extract + 'ml/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Serbest Flor</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.free_fluorine)? history.free_fluorine + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.free_fluorine)? serviceData.free_fluorine + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>SiF6</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.sif6)? history.sif6 + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.sif6)? serviceData.sif6 + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Bakteri</b-td>
            <b-td v-if="formType === '1'" />
            <b-td
              v-for="(history,bakey) in serviceHistory"
              :key="bakey"
              class="text-center"
            >
              {{ (history.bacteria)? history.bacteria : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.bacteria)? serviceData.bacteria : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Al Noktası</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.al_point)? history.al_point + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.al_point)? serviceData.al_point + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Zirkonyum</b-td>
            <b-td v-if="formType === '1'">
              mg/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.zirconium)? history.zirconium + 'mg/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.zirconium)? serviceData.zirconium + 'mg/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Klor</b-td>
            <b-td v-if="formType === '1'">
              mg/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.chlorine)? history.chlorine + 'mg/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.chlorine)? serviceData.chlorine + 'mg/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Su Sertliği</b-td>
            <b-td v-if="formType === '1'">
              od
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.water_hardness)? history.water_hardness + 'od' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.water_hardness)? serviceData.water_hardness + 'od' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Kalay</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.tin)? history.tin + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.tin)? serviceData.tin + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>NaOH</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.naoh)? history.naoh + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.naoh)? serviceData.naoh + 'g/l' : '-' }}
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Refraktometre Değeri</b-td>
            <b-td v-if="formType === '1'">
              %
            </b-td>
            <b-td
              v-for="(history,rkey) in serviceHistory"
              :key="rkey"
              class="text-center"
            >
              <span v-if="history.refractometer">
                %{{ history.refractometer | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
            <b-td class="text-center">
              <span v-if="serviceData.refractometer">
                %{{ serviceData.refractometer | toNumber }}
              </span>
              <span v-else>-</span>
            </b-td>
          </b-tr>
          <b-tr v-if="formType === '2'">
            <b-td>Kaplama Ağırlığı</b-td>
            <b-td v-if="formType === '1'">
              g/m2
            </b-td>
            <b-td
              v-for="(history,rkey) in serviceHistory"
              :key="rkey"
              class="text-center"
            >
              {{ (history.coating_weight)? history.coating_weight + 'g/m2' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.coating_weight)? serviceData.coating_weight + 'g/m2' : '-' }}
            </b-td>
          </b-tr>
          <!-- new lines -->
          <b-tr v-if="formType === '2'">
            <b-td>Fe+2</b-td>
            <b-td v-if="formType === '1'">
              g/l
            </b-td>
            <b-td
              v-for="(history,mikey) in serviceHistory"
              :key="mikey"
              class="text-center"
            >
              {{ (history.fe2)? history.fe2 + 'g/l' : '-' }}
            </b-td>
            <b-td class="text-center">
              {{ (serviceData.fe2)? serviceData.fe2 + 'g/l' : '-' }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BListGroup, BListGroupItem, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import GeneralSituation from '@/views/Services/Components/GeneralSituation'

export default {
  name: 'ServiceCard',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    GeneralSituation,
  },
  props: {
    serviceData: {
      type: Object,
      required: true,
    },
    formType: {
      type: String,
      default: '1',
    },
    serviceHistory: {
      type: Array,
      default: null,
    },
  },
  computed: {
    materialTypes() {
      return this.$store.getters['materialTypes/getMaterial_types']
    },
    usedOils() {
      return this.$store.getters['usedOils/getUsed_oils']
    },
    usedProcesses() {
      return this.$store.getters['usedProcesses/dataList']
    },
    usedProducts() {
      return this.$store.getters['usedProducts/dataList']
    },
    appearances() {
      return this.$store.getters['appearances/getAppearances']
    },
    foreignOilRatios() {
      return this.$store.getters['foreignOilRatios/getForeign_oil_ratios']
    },
    generalSituations() {
      return [
        {
          id: 1,
          title: 'Yeşil',
          color: '#447c00',
        },
        {
          id: 2,
          title: 'Sarı',
          color: '#b7af00',
        },
        {
          id: 3,
          title: 'Kırmızı',
          color: '#c90000',
        },
      ]
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    selectedData(data, id) {
      const res = data.filter(e => Number(e.id) === Number(id))
      if (res[0]) {
        return res[0].title
      }
      return null
    },
    getData() {
      if (this.materialTypes.length <= 0) {
        this.getMaterialTypes()
      }
      if (this.usedOils.length <= 0) {
        this.getUsedOils()
      }
      if (this.appearances.length <= 0) {
        this.getAppearances()
      }
      if (this.foreignOilRatios.length <= 0) {
        this.getForeignOilRatios()
      }
    },
    getMaterialTypes() {
      this.$store.dispatch('materialTypes/material_typesList', {
        select: [
          'material_types.id AS id',
          'material_types.title AS title',
        ],
      })
    },
    getUsedOils() {
      this.$store.dispatch('usedOils/used_oilsList', {
        select: [
          'used_oils.id AS id',
          'used_oils.title AS title',
        ],
      })
    },
    getAppearances() {
      this.$store.dispatch('appearances/appearancesList', {
        select: [
          'appearances.id AS id',
          'appearances.title AS title',
        ],
      })
    },
    getForeignOilRatios() {
      this.$store.dispatch('foreignOilRatios/foreign_oil_ratiosList', {
        select: [
          'foreign_oil_ratios.id AS id',
          'foreign_oil_ratios.title AS title',
        ],
      })
    },
  },
}
</script>

<style scoped>

</style>
