<template>
  <b-form-group
    label="Yorum / Notlar"
    label-for="notes"
  >
    <b-form-textarea
      id="notes"
      v-model="formData.notes"
      placeholder="Yorum / Notlar"
      rows="6"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'TotalAlkalinity',
  components: {
    BFormGroup, BFormTextarea,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
