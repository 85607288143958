<template>
  <b-form-group
    label="İlgili Kişi"
    label-for="related_person"
  >
    <b-form-input
      id="related_person"
      v-model="formData.related_person"
      placeholder="İlgili Kişi"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'RelatedPerson',
  components: {
    BFormGroup, BFormInput,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
