<template>
  <b-form-group
    label="Refraktometre Değeri"
    label-for="refractometer"
  >
    <b-input-group>
      <cleave
        id="refractometer"
        v-model="formData.refractometer"
        class="form-control"
        :raw="true"
        :options="options.floatVal"
        placeholder="Refraktometre Değeri"
      />
      <b-input-group-append is-text>
        %
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Refractometer',
  components: {
    BFormGroup,
    Cleave,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      options: {
        floatVal: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
