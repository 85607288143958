<template>
  <b-form-group
    label="Fe+2"
    label-for="fe2"
  >
    <b-form-input
      id="fe2"
      v-model="formData.fe2"
      placeholder="Fe+2"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Fe2',
  components: {
    BFormGroup, BFormInput,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
