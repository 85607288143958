<template>
  <b-form-group
    label="Mantar Testi"
    label-for="mildew"
  >
    <b-form-input
      id="mildew"
      v-model="formData.mildew"
      placeholder="Mantar Testi"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Mildew',
  components: {
    BFormGroup, BFormInput,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
