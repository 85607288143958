<template>
  <div>
    <b-form-group
      label="Kullanılan Yağ"
      label-for="id_used_oils"
    >
      <v-select
        id="id_used_oils"
        v-model="formData.id_used_oils"
        placeholder="Seçiniz"
        label="title"
        :reduce="item => item.id"
        :options="usedOils"
        @input="changeUsedOils"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'UsedOils',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    usedOils() {
      return this.$store.getters['usedOils/getUsed_oils']
    },
  },
  created() {
    this.getUsedOils()
  },
  methods: {
    changeUsedOils() {
      if (this.formData.id_used_oils) {
        this.getUsedOil(this.formData.id_used_oils)
        // this.formData.refVal = this.usedOils.find(e => e.id === this.formData.id_used_oils).refractometer
      }
    },
    getUsedOil(id) {
      this.$store.dispatch('usedOils/used_oilView', id).then(response => {
        if (response) {
          this.formData.refVal = response.refractometer
        }
      })
    },
    getUsedOils() {
      this.$store.dispatch('usedOils/used_oilsList', {
        select: [
          'used_oils.id AS id',
          'used_oils.title AS title',
          'used_oils.refractometer AS refractometer',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
