<template>
  <b-form-group
    label="Su Sertliği"
    label-for="water_hardness"
  >
    <b-input-group>
      <b-form-input
        id="water_hardness"
        v-model="formData.water_hardness"
        placeholder="Su Sertliği"
      />
      <b-input-group-append is-text>
        od
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'WaterHardness',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
