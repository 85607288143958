<template>
  <b-form-group
    label="Kullanılan Ürün"
    label-for="id_used_products"
  >
    <v-select
      id="id_used_products"
      v-model="formData.id_used_products"
      placeholder="Seçiniz"
      label="title"
      :reduce="item => item.id"
      :options="usedProducts"
      :disabled="!formData.id_used_processes"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'UsedProducts',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    usedProducts() {
      return this.$store.getters['usedProducts/dataList']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
