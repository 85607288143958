<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
      >
        <bench-name />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <related-person />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <related-phone />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <analysed />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <used-processes />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <used-products />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <ph />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <conductivity />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <total-alkalinity />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <total-acid />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <free-acid />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <accelerator />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <phosphate-point />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <iron-point />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <zn />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <mn />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <ni />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <free-alkalinity />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <total-oil-extract />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <free-fluorine />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <sif6 />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <bacteria />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <al-point />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <zirconium />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <chlorine />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <water-hardness />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <tin />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <na-oh />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <refractometer />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <coating-weight />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <fe2 />
      </b-col>
      <b-col cols="12">
        <notes />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import Accelerator from '@/views/Services/elements/Accelerator.vue'
import Analysed from '@/views/Services/elements/Analysed.vue'
import Conductivity from '@/views/Services/elements/Conductivity.vue'
import Fe2 from '@/views/Services/elements/Fe2.vue'
import FreeAcid from '@/views/Services/elements/FreeAcid.vue'
import IronPoint from '@/views/Services/elements/IronPoint.vue'
import Notes from '@/views/Services/elements/Notes.vue'
import Ph from '@/views/Services/elements/Ph.vue'
import PhosphatePoint from '@/views/Services/elements/PhosphatePoint.vue'
import RelatedPerson from '@/views/Services/elements/RelatedPerson.vue'
import RelatedPhone from '@/views/Services/elements/RelatedPhone.vue'
import TotalAcid from '@/views/Services/elements/TotalAcid.vue'
import TotalAlkalinity from '@/views/Services/elements/TotalAlkalinity.vue'
import UsedProcesses from '@/views/Services/elements/UsedProcesses.vue'
import UsedProducts from '@/views/Services/elements/UsedProducts.vue'
import Zn from '@/views/Services/elements/Zn.vue'
import Mn from '@/views/Services/elements/Mn.vue'
import Ni from '@/views/Services/elements/Ni.vue'
import FreeAlkalinity from '@/views/Services/elements/FreeAlkalinity.vue'
import TotalOilExtract from '@/views/Services/elements/TotalOilExtract.vue'
import FreeFluorine from '@/views/Services/elements/FreeFluorine.vue'
import Sif6 from '@/views/Services/elements/Sif6.vue'
import Bacteria from '@/views/Services/elements/Bacteria.vue'
import Refractometer from '@/views/Services/elements/Refractometer.vue'
import AlPoint from '@/views/Services/elements/AlPoint.vue'
import Zirconium from '@/views/Services/elements/Zirconium.vue'
import Chlorine from '@/views/Services/elements/Chlorine.vue'
import WaterHardness from '@/views/Services/elements/WaterHardness.vue'
import Tin from '@/views/Services/elements/Tin.vue'
import NaOh from '@/views/Services/elements/NaOh.vue'
import CoatingWeight from '@/views/Services/elements/CoatingWeight.vue'
import BenchName from '@/views/Services/elements/BenchName.vue'

export default {
  name: 'ServiceForm2',
  components: {
    BRow,
    BCol,
    BCard,
    BenchName,
    Accelerator,
    Analysed,
    Conductivity,
    Fe2,
    FreeAcid,
    IronPoint,
    Notes,
    Ph,
    PhosphatePoint,
    RelatedPerson,
    RelatedPhone,
    TotalAcid,
    TotalAlkalinity,
    UsedProcesses,
    UsedProducts,
    Zn,
    Mn,
    Ni,
    FreeAlkalinity,
    TotalOilExtract,
    FreeFluorine,
    Sif6,
    Bacteria,
    Refractometer,
    AlPoint,
    Zirconium,
    Chlorine,
    WaterHardness,
    Tin,
    NaOh,
    CoatingWeight,
  },
}
</script>
