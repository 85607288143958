<template>
  <b-form-group
    label="İlgili Telefon"
    label-for="related_phone"
  >
    <b-input-group>
      <b-input-group-prepend is-text>
        TR (+90)
      </b-input-group-prepend>
      <cleave
        id="related_phone"
        v-model="formData.related_phone"
        class="form-control"
        :raw="false"
        :options="options.phone"
        placeholder="İlgili Telefon"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'RelatedPhone',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
