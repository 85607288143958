<template>
  <b-row>
    <b-col
      v-if="loading"
      cols="12"
    >
      <b-alert show>
        <div class="alert-body text-center">
          <b-spinner />
          <p>Lütfen Bekleyiniz...</p>
        </div>
      </b-alert>
    </b-col>
    <b-col
      cols="12"
      sm="12"
      :lg="photos.length > 0? 4 : 12"
    >
      <b-card title="Fotoğraf Yükle">
        <b-form-group>
          <b-form-file
            v-model="uploadData.upload_image"
            placeholder="Bir görsel seçin veya buraya bırakın..."
            drop-placeholder="Görseli buraya bırakın..."
            browse-text="Fotoğraf Seçin"
          />
        </b-form-group>
        <div class="text-center">
          <b-button
            variant="success"
            @click="uploadPhotos"
          >
            <FeatherIcon icon="UploadIcon" />
            Fotoğrafı Yükle
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col v-if="photos.length > 0">
      <b-card title="Yüklenen Fotoğraflar">
        <b-row>
          <b-col
            v-for="item in photos"
            :key="item.id"
            cols="12"
            sm="6"
            lg="3"
          >
            <div class="bg-light p-1 rounded-1 rounded mb-2">
              <b-img
                :src="item.filename"
                fluid-grow
                rounded
                class="cursor-pointer"
                @click="viewImage(item)"
              />
              <b-button
                class="mt-1"
                variant="primary"
                block
                size="sm"
                @click="viewImage(item)"
              >
                <FeatherIcon icon="EyeIcon" />
                Görüntüle
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-modal
      v-model="modalShow"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-img
        :src="modalImage.filename"
        fluid-grow
      />
      <div class="text-center">
        <b-button
          class="mt-1"
          variant="gradient-danger"
          @click="removeImage(modalImage.id)"
        >
          <FeatherIcon icon="TrashIcon" />
          Fotoğrafı Sil
        </b-button>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormFile, BImg, BButton, BModal, BSpinner, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'PhotoUpload',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BCard,
    BImg,
    BButton,
    BModal,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      loading: false,
      modalImage: {},
      modalShow: false,
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    photos() {
      return this.$store.getters['servicePhotos/dataList']
    },
    uploadData() {
      return this.$store.getters['servicePhotos/dataItem']
    },
  },
  methods: {
    uploadPhotos() {
      this.loading = true
      this.uploadData.id_services = this.formData.id
      this.$store.dispatch('servicePhotos/saveData', this.uploadData)
        .then(res => {
          if (res.status) {
            if (this.formData.id) {
              this.getPhotos({ id: this.formData.id })
            } else {
              this.getPhotos({ uploadCode: res.uploadCode })
            }
            this.loading = false
            this.formData.uploadCode = res.uploadCode
            this.uploadData.uploadCode = res.uploadCode
          }
        })
    },
    removeImage(id) {
      this.$store.dispatch('servicePhotos/removeData', id)
        .then(res => {
          if (res.status) {
            this.getPhotos(this.formData.id)
            this.modalShow = false
          }
        })
    },
    getPhotos(params) {
      this.$store.dispatch('servicePhotos/getDataList', params)
    },
    viewImage(item) {
      this.modalShow = true
      this.modalImage = item
    },
  },
}
</script>
