<template>
  <b-form-group
    label="Konsantrasyon"
    label-for="concentration"
  >
    <cleave
      id="concentration"
      v-model="formData.concentration"
      class="form-control"
      :raw="true"
      :options="options.floatVal"
      placeholder="Konsantrasyon"
      readonly
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Concentration',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        floatVal: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
