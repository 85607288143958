<template>
  <b-form-group
    label="Fosfat Noktası"
    label-for="phosphate_point"
  >
    <b-form-input
      id="phosphate_point"
      v-model="formData.phosphate_point"
      placeholder="Fosfat Noktası"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'PhosphatePoint',
  components: {
    BFormGroup, BFormInput,
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
  },
}
</script>
